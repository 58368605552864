/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import axios from 'axios';
import { useRouter } from 'next/router';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import useSWR from 'swr';
import { useAuthContext } from './AuthContext';
// import { useResponseHistoryContext } from './ResponseHistoryContext';
// import { useNewCampaignContext } from './CampaignNewContext';
import {
  BrandPersona,
  AudiencePersona,
  ProductPersona,
} from '../types/Persona.type';
import {
  APIErrors,
  AudiencePersonaDeleteInput,
  AudiencePersonaEditInput,
  AudiencePersonaPopupInput,
  BrandPersonaDeleteInput,
  BrandPersonaEditInput,
  BrandPersonaPopupInput,
  ProductPersonaDeleteInput,
  ProductPersonaEditInput,
  ProductPersonaPopupInput,
} from '../types/API';
import { reload } from 'firebase/auth';

interface PersonaContextInterface {
  brandPersonas: BrandPersona[] | undefined;
  audiencePersonas: AudiencePersona[] | undefined;
  productPersonas: ProductPersona[] | undefined;

  saveBrand: ({
    brand,
    industry,
    profilePic,
    description,
    website,
    tone,
    brandPositioning,
    competitors,
    brandReplacementWords,
    brandNoNoWords,
    brandUseSparinglyWords,
  }: BrandPersonaPopupInput) => Promise<string | undefined>;
  saveAudience: ({
    audience,
    brand,
    gender,
    age,
    occupation,
    location,
    painpoints,
    profilePic,
    goals,
    triggers,
    barriers,
  }: AudiencePersonaPopupInput) => Promise<void>;
  saveProduct: ({
    brand,
    product,
    description,
  }: ProductPersonaPopupInput) => Promise<void>;
  updateBrand: ({
    brand,
    industry,
    profilePic,
    description,
    website,
    tone,
    id,
    brandPositioning,
    competitors,
    brandReplacementWords,
    brandNoNoWords,
    brandUseSparinglyWords,
  }: BrandPersonaEditInput) => Promise<void>;
  updateAudience: ({
    age,
    audience,
    brand,
    gender,
    id,
    location,
    occupation,
    painpoints,
    profilePic,
    goals,
    triggers,
    barriers,
  }: AudiencePersonaEditInput) => Promise<void>;
  updateProduct: ({
    brand,
    description,
    id,
    product,
  }: ProductPersonaEditInput) => Promise<void>;
  updateToOrganizationPersona: (
    id: string,
    personaType: string,
    revert: boolean
  ) => Promise<void>;
  deleteBrand: ({ id }: BrandPersonaDeleteInput) => Promise<void>;
  deleteAudience: ({ id }: AudiencePersonaDeleteInput) => Promise<void>;
  deleteProduct: ({ id }: ProductPersonaDeleteInput) => Promise<void>;
  mutateAudSWR: () => Promise<void>;
  refreshAllPersonas: () => Promise<void>;
  refreshAllContentTypes: boolean;
}

const PersonaContext = createContext<PersonaContextInterface | undefined>(
  undefined
);

export const PersonaContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const router = useRouter();
  const [loading, setLoading] = useState<boolean>(false);
  const [refreshPersonas, setRefreshPersonas] = useState<boolean>(false);
  const [refreshAllContentTypes, setRefreshAllContentTypes] =
    useState<boolean>(false);
  const [generalErrors, setGeneralErrors] = useState<string>('');
  const queryPersonaId = router.query.personaId;
  const queryPersonaTemplateId = router.query.personaTemplateId;
  const [personas, setPersonas] = useState<AudiencePersona[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const [lastDocId, setLastDocId] = useState<string | null>(null);
  const { isSignedIn, refetchUserData } = useAuthContext();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  const fetcher = (url: string) => axios.get(url).then((res) => res.data);

  //for retrieving personas and separating them into different fields
  const { data: brandPersonas, mutate: mutateBrandPersonasSWR } = useSWR<
    BrandPersona[],
    Error
    //any api below is triggered by sign in
  >(isSignedIn || refreshPersonas ? '/api/personas/retrieveBrands' : null);

  // Initial fetch with useSWR
  const {
    data,
    error,
    mutate: swrMutate,
  } = useSWR<{ data: AudiencePersona[]; hasMore: boolean }, Error>(
    isSignedIn || refreshPersonas
      ? `/api/personas/retrieveAudiences?limit=10${
          lastDocId ? `&lastDocId=${lastDocId}` : ''
        }`
      : null,
    fetcher
  );

  useEffect(() => {
    if (data) {
      setPersonas((prev) => {
        const existingIds = new Set(prev.map((persona) => persona.id));
        const newPersonas = data.data.filter(
          (persona) => !existingIds.has(persona.id)
        );
        return [...prev, ...newPersonas];
      });
      setHasMore(data.hasMore);
      if (data.data.length > 0) {
        setLastDocId(data.data[data.data.length - 1].id);
      }
    }
  }, [data]);

  useEffect(() => {
    const fetchMore = async () => {
      if (
        hasMore &&
        !loading &&
        lastDocId !== personas[personas.length - 1].id
      ) {
        setLoading(true);
        try {
          console.log('useEffect triggered, hasMore:', hasMore);
          const response = await axios.get(
            `/api/personas/retrieveAudiences?limit=10${
              lastDocId ? `&lastDocId=${lastDocId}` : ''
            }`
          );
          const { data: newData, hasMore: newHasMore } = response.data;

          setPersonas((prev) => {
            const existingIds = new Set(prev.map((persona) => persona.id));
            const newPersonas = newData.filter(
              (persona: { id: string }) => !existingIds.has(persona.id)
            );
            return [...prev, ...newPersonas];
          });
          setHasMore(newHasMore && newData.length >= 8);
          if (newData.length > 8) {
            setLastDocId(newData[newData.length - 1].id);
          }
        } finally {
          setLoading(false);
        }
      }
    };

    if (hasMore) {
      fetchMore();
    }
  }, [hasMore]);

  // const { data: audiencePersonas, mutate: mutateAudiencePersonasSWR } = useSWR<
  //   AudiencePersona[],
  //   Error
  //   //any api below is triggered by sign in
  // >(isSignedIn || refreshPersonas ? '/api/personas/retrieveAudiences' : null);

  const { data: productPersonas, mutate: mutateProductPersonasSWR } = useSWR<
    ProductPersona[],
    Error
    //any api below is triggered by sign in
  >(isSignedIn || refreshPersonas ? '/api/personas/retrieveProducts' : null);

  const saveBrand = async (BrandPersonaSaveInput: BrandPersonaPopupInput) => {
    const {
      brand,
      website,
      description,
      industry,
      tone,
      profilePic,
      brandPositioning,
      competitors,
      brandReplacementWords,
      brandNoNoWords,
      brandUseSparinglyWords,
    } = BrandPersonaSaveInput;
    setLoading(true);
    setGeneralErrors('');
    try {
      const data = await axios.post('/api/personas/saveBrand', {
        brand,
        website,
        description,
        industry,
        tone,
        profilePic,
        brandPositioning,
        competitors,
        brandReplacementWords,
        brandNoNoWords,
        brandUseSparinglyWords,
      });
      await mutateBrandPersonasSWR();
      // await refetchUserData();
      console.log('brand saved and updated');

      return data.data as string;
    } catch (error) {
      const generalErr = error as APIErrors;
      setGeneralErrors(
        generalErr.error || 'Something went wrong, please try again'
      );
    } finally {
      setLoading(false);
    }
  };

  const saveAudience = async (
    AudiencePersonaSaveInput: AudiencePersonaPopupInput
  ) => {
    const {
      audience,
      brand,
      gender,
      age,
      occupation,
      location,
      painpoints,
      profilePic,
      goals,
      triggers,
      barriers,
    } = AudiencePersonaSaveInput;
    setLoading(true);
    setGeneralErrors('');
    try {
      const aud = await axios.post('/api/personas/saveAudience', {
        audience,
        brand,
        gender,
        age,
        occupation,
        location,
        painpoints,
        profilePic,
        goals,
        triggers,
        barriers,
      });
      console.log('aud', aud.data);
      const brandfixed = brand as { value: string; label: string } | undefined;
      console.log(brandfixed, 'this is brandfixed');

      await mutateAudiencePersonasSWR(
        {
          age: age,
          audience: audience,
          brand: brandfixed,
          gender: gender,
          id: aud.data,
          location: location,
          occupation: occupation,
          profilePic: profilePic,
          painpoints: painpoints,
          goals: goals,
          triggers: triggers,
          barriers: barriers,
        },
        'create'
      );
      console.log('audience saved and updated');
    } catch (error) {
      const generalErr = error as APIErrors;
      setGeneralErrors(
        generalErr.error || 'Something went wrong, please try again'
      );
    } finally {
      setLoading(false);
    }
  };

  const saveProduct = async (
    ProductPersonaSaveInput: ProductPersonaPopupInput
  ) => {
    const { brand, product, description } = ProductPersonaSaveInput;
    setLoading(true);
    setGeneralErrors('');
    try {
      await axios.post('/api/personas/saveProduct', {
        brand,
        product,
        description,
      });
      await mutateProductPersonasSWR();
      console.log('Product saved and updated');
    } catch (error) {
      const generalErr = error as APIErrors;
      setGeneralErrors(
        generalErr.error || 'Something went wrong, please try again'
      );
    } finally {
      setLoading(false);
    }
  };

  const mutateAudiencePersonasSWR = async (
    updatedData?: Partial<AudiencePersona>,
    operation?: 'create' | 'update' | 'delete' | 'brandDelete'
  ) => {
    if (updatedData) {
      setPersonas((prev) => {
        if (operation === 'delete') {
          return prev.filter((persona) => persona.id !== updatedData.id);
        } else if (operation === 'update') {
          return prev.map((persona) =>
            persona.id === updatedData.id
              ? { ...persona, ...updatedData }
              : persona
          );
        } else if (operation === 'create') {
          return [updatedData as AudiencePersona, ...prev];
        } else if (operation === 'brandDelete') {
          return prev.filter(
            (persona) => persona.brand.value !== updatedData.brandID
          );
        }
        return prev;
      });
    }

    await swrMutate(); // Refetch data to ensure the state is consistent with the server

    // After mutate, re-fetch more data if there are more items
    if (hasMore) {
      const fetchMore = async () => {
        setLoading(true);
        try {
          const response = await axios.get(
            `/api/personas/retrieveAudiences?limit=10${
              lastDocId ? `&lastDocId=${lastDocId}` : ''
            }`
          );
          const { data: newData, hasMore: newHasMore } = response.data;

          setPersonas((prev) => {
            const existingIds = new Set(prev.map((persona) => persona.id));
            const newPersonas = newData.filter(
              (persona: { id: string }) => !existingIds.has(persona.id)
            );
            return [...prev, ...newPersonas];
          });
          setHasMore(newHasMore);
          if (newData.length > 0) {
            setLastDocId(newData[newData.length - 1].id);
          }
        } finally {
          setLoading(false);
        }
      };

      fetchMore();
    }
  };

  const updateBrand = async (BrandPersonaSaveInput: BrandPersonaEditInput) => {
    const {
      brand,
      website,
      description,
      industry,
      tone,
      profilePic,
      id,
      brandPositioning,
      competitors,
      brandReplacementWords,
      brandNoNoWords,
      brandUseSparinglyWords,
    } = BrandPersonaSaveInput;
    setLoading(true);
    setGeneralErrors('');
    try {
      await axios.post('/api/personas/updateBrands', {
        brand,
        website,
        description,
        industry,
        tone,
        profilePic,
        id,
        brandPositioning,
        competitors,
        brandReplacementWords,
        brandNoNoWords,
        brandUseSparinglyWords,
      });
      await mutateBrandPersonasSWR();
      // await mutateAudiencePersonasSWR();
      await mutateProductPersonasSWR();
      setRefreshAllContentTypes(true);
      console.log('brand saved and updated');
    } catch (error) {
      const generalErr = error as APIErrors;
      setGeneralErrors(
        generalErr.error || 'Something went wrong, please try again'
      );
    } finally {
      setRefreshAllContentTypes(false);
      setLoading(false);
    }
  };

  const updateAudience = async (
    AudiencePersonaSaveInput: AudiencePersonaEditInput
  ) => {
    const {
      age,
      audience,
      brand,
      gender,
      id,
      location,
      occupation,
      painpoints,
      profilePic,
      goals,
      triggers,
      barriers,
    } = AudiencePersonaSaveInput;
    setLoading(true);
    setGeneralErrors('');
    try {
      await axios.post('/api/personas/updateAudience', {
        age,
        audience,
        brand,
        gender,
        id,
        location,
        occupation,
        profilePic,
        painpoints,
        goals,
        triggers,
        barriers,
      });
      await mutateBrandPersonasSWR();
      const brandfixed = brand as { value: string; label: string } | undefined;
      await mutateAudiencePersonasSWR(
        {
          age: age,
          audience: audience,
          brand: brandfixed,
          gender: gender,
          id: id,
          location: location,
          occupation: occupation,
          profilePic: profilePic,
          painpoints: painpoints,
          goals: goals,
          triggers: triggers,
          barriers: barriers,
        },
        'update'
      );
      await mutateProductPersonasSWR();
      setRefreshAllContentTypes(true);
      console.log('Audience saved and updated');
    } catch (error) {
      const generalErr = error as APIErrors;
      setGeneralErrors(
        generalErr.error || 'Something went wrong, please try again'
      );
    } finally {
      setRefreshAllContentTypes(false);
      setLoading(false);
    }
  };

  const updateProduct = async (
    ProductPersonaSaveInput: ProductPersonaEditInput
  ) => {
    const { product, brand, description, id } = ProductPersonaSaveInput;
    setLoading(true);
    setGeneralErrors('');
    try {
      await axios.post('/api/personas/updateProduct', {
        product,
        brand,
        description,
        id,
      });
      await mutateBrandPersonasSWR();
      await mutateAudiencePersonasSWR();
      await mutateProductPersonasSWR();
      setRefreshAllContentTypes(true);
      console.log('Product saved and updated');
    } catch (error) {
      const generalErr = error as APIErrors;
      setGeneralErrors(
        generalErr.error || 'Something went wrong, please try again'
      );
    } finally {
      setRefreshAllContentTypes(false);
      setLoading(false);
    }
  };

  const updateToOrganizationPersona = async (
    id: string,
    personaType: string,
    revert: boolean
  ) => {
    setLoading(true);
    setGeneralErrors('');
    try {
      // console.log('quick test, id, and personatype', id, personaType)
      await axios.post('/api/personas/updateToOrganizationPersona', {
        id: id,
        personaType: personaType,
        revert: revert,
      });
      await mutateBrandPersonasSWR();
      // const brandfixed = brand as { value: string; label: string } | undefined;
      if (personaType === 'audience') {
        await mutateAudiencePersonasSWR(
          {
            id,
            organizationPersona: revert ? false : true,
          },
          'update'
        );
      }
      await mutateProductPersonasSWR();
      setRefreshAllContentTypes(true);
      console.log('Organization Persona saved and updated');
    } catch (error) {
      const generalErr = error as APIErrors;
      setGeneralErrors(
        generalErr.error || 'Something went wrong, please try again'
      );
    } finally {
      setRefreshAllContentTypes(false);
      setLoading(false);
    }
  };

  const deleteBrand = async (BrandPersonaDelInput: BrandPersonaDeleteInput) => {
    const { id } = BrandPersonaDelInput;
    setLoading(true);
    setGeneralErrors('');
    try {
      await axios.post('/api/personas/deleteBrand', {
        id,
      });
      await mutateBrandPersonasSWR();
      await mutateAudiencePersonasSWR(
        {
          brandID: id,
        },
        'brandDelete'
      );
      await mutateProductPersonasSWR();

      console.log('brand deleted and updated');
    } catch (error) {
      const generalErr = error as APIErrors;
      setGeneralErrors(
        generalErr.error || 'Something went wrong, please try again'
      );
    } finally {
      setLoading(false);
    }
  };

  const deleteAudience = async (
    AudiencePersonaDelInput: AudiencePersonaDeleteInput
  ) => {
    const { id } = AudiencePersonaDelInput;
    setLoading(true);
    setGeneralErrors('');
    try {
      await axios.post('/api/personas/deleteAudience', {
        id,
      });
      await mutateAudiencePersonasSWR({ id }, 'delete');

      console.log('audience deleted and updated');
    } catch (error) {
      const generalErr = error as APIErrors;
      setGeneralErrors(
        generalErr.error || 'Something went wrong, please try again'
      );
    } finally {
      setLoading(false);
    }
  };

  const deleteProduct = async (
    ProductPersonaDelInput: ProductPersonaDeleteInput
  ) => {
    const { id } = ProductPersonaDelInput;
    setLoading(true);
    setGeneralErrors('');
    try {
      await axios.post('/api/personas/deleteProduct', {
        id,
      });
      await mutateProductPersonasSWR();

      console.log('product deleted and updated');
    } catch (error) {
      const generalErr = error as APIErrors;
      setGeneralErrors(
        generalErr.error || 'Something went wrong, please try again'
      );
    } finally {
      setLoading(false);
    }
  };

  const mutateAudSWR = async () => {
    try {
      await mutateAudiencePersonasSWR();
      console.log('Audience saved and updated');
    } catch (error) {
      const generalErr = error as APIErrors;
      setGeneralErrors(
        generalErr.error || 'Something went wrong, please try again'
      );
    }
  };

  const refreshAllPersonas = async () => {
    try {
      setRefreshPersonas(true);
      await mutateBrandPersonasSWR();
      await mutateAudiencePersonasSWR();
      await mutateProductPersonasSWR();
    } catch (error) {
      const generalErr = error as APIErrors;
      setGeneralErrors(
        generalErr.error || 'Something went wrong, please try again'
      );
    } finally {
      setRefreshPersonas(false);
    }
  };

  return (
    <PersonaContext.Provider
      value={{
        brandPersonas,
        audiencePersonas: personas,
        productPersonas,

        saveBrand,
        saveAudience,
        saveProduct,
        updateBrand,
        updateAudience,
        updateProduct,
        updateToOrganizationPersona,
        deleteBrand,
        deleteAudience,
        deleteProduct,
        mutateAudSWR,
        refreshAllPersonas,
        refreshAllContentTypes,
      }}
    >
      {children}
    </PersonaContext.Provider>
  );
};

export const usePersonaContext = (): PersonaContextInterface => {
  const context = useContext(PersonaContext);
  if (context === undefined) {
    throw new Error('PersonaContext must be within PersonaContextProvider');
  }

  return context;
};
