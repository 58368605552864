import axios from 'axios';
import { useRouter } from 'next/router';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import useSWR, { KeyedMutator } from 'swr';
import {
  AlphaUser,
  APIErrors,
  FeatureLimitation,
  FeatureLimitationsOutput,
  GroupedUserGeneratedResponses,
  StripeTeamSubscriptionOutput,
} from '../types/API';
import { UserStripeSubscriptionData } from '../types/Stripe.type';
import { TeamMember } from '../types/Team.type';
import { useAuthContext } from './AuthContext';

interface SettingsContextInterface {
  numInvitesRemaining: number;
  inviteLink: string;
  stripeSubscriptionData: UserStripeSubscriptionData | undefined;
  teamMembers: TeamMember[];
  organizationMembers?: TeamMember[][];
  featureLimitationsList?: FeatureLimitation[];
  FLLoading: boolean;
  userSelectedDifferentFL: (
    FL: FeatureLimitation,
    userID: string
  ) => Promise<void>;
  mutateFLSWR: () => Promise<void>;
  mutateOrganizationMembers: () => Promise<void>;
}

const SettingsContext = createContext<SettingsContextInterface | undefined>(
  undefined
);

export const SettingsContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { authUser, userData, refetchUserData } = useAuthContext();

  const [numInvitesRemaining, setNumInvitesRemaining] = useState(0);
  const [inviteLink, setInviteLink] = useState('');
  const [stripeSubscriptionData, setStripeSubscriptionData] = useState<
    UserStripeSubscriptionData | undefined
  >();
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
  const [organizationMembers, setOrganizationMembers] = useState<
    TeamMember[][]
  >([]);
  const [featureLimitations, setFeatureLimitations] = useState<
    FeatureLimitation[] | undefined
  >();
  const [FeatureLimitLoading, setFeatureLimitLoading] = useState(false);
  const [generalErrors, setGeneralErrors] = useState<string>('');

  const {
    data: stripeTeamSubscriptionDataSWR,
    mutate: mutateStripeTeamSubscriptionDataSWR,
  } = useSWR<StripeTeamSubscriptionOutput, Error>(
    authUser && authUser.uid ? '/api/team/getTeamSubscriptionData' : null
  );

  const { data: orgSubscriptionDataSWR, mutate: mutateOrgSubscriptionDataSWR } =
    useSWR<TeamMember[][], Error>(
      authUser && authUser.uid && userData?.organizationOwner
        ? '/api/team/getOrganizationSubscriptionData'
        : null
    );

  const {
    data: featureLimitationDataSWR,
    mutate: mutateFeatureLimitationDataSWR,
  } = useSWR<FeatureLimitationsOutput, Error>(
    authUser && authUser.uid ? '/api/team/getFeatureLimitations' : null
  );

  useEffect(() => {
    if (userData && userData.stripe) {
      setStripeSubscriptionData(userData.stripe);
    }
  }, [userData]);

  useEffect(() => {
    if (orgSubscriptionDataSWR) {
      setOrganizationMembers(orgSubscriptionDataSWR);
    }
  }, [orgSubscriptionDataSWR]);

  useEffect(() => {
    if (stripeTeamSubscriptionDataSWR) {
      const { teamMembers, numInvitesRemaining, inviteCode } =
        stripeTeamSubscriptionDataSWR;
      setNumInvitesRemaining(numInvitesRemaining);
      if (numInvitesRemaining === 0) {
        setInviteLink('Max number of team members reached');
      } else {
        setInviteLink(
          `${window.location.origin}/signin?inviteCode=${inviteCode}`
        );
      }
      setTeamMembers(teamMembers);
    }
  }, [stripeTeamSubscriptionDataSWR]);

  useEffect(() => {
    if (featureLimitationDataSWR) {
      const { featureLimitations } = featureLimitationDataSWR;
      setFeatureLimitations(featureLimitations);
    }
  }, [featureLimitationDataSWR]);

  const userSelectedDifferentFL = async (
    FL: FeatureLimitation,
    userID: string
  ) => {
    const { id, name, allowedFeatures, pinnedFeatures } = FL;
    setFeatureLimitLoading(true);
    setGeneralErrors('');
    try {
      const data = await axios.post('/api/user/updateUserFL', {
        id,
        name,
        allowedFeatures,
        pinnedFeatures,
        userID,
      });
      await refetchUserData();
      await mutateFeatureLimitationDataSWR();
      if (id === authUser?.uid) {
        location.reload();
      }
      // await refetchUserData();
      console.log('FL saved and updated');
    } catch (error) {
      const generalErr = error as APIErrors;
      setGeneralErrors(
        generalErr.error || 'Something went wrong, please try again'
      );
    } finally {
      setFeatureLimitLoading(false);
    }
  };

  const mutateFLSWR = async () => {
    try {
      await mutateFeatureLimitationDataSWR();
      console.log('FL saved and updated');
    } catch (error) {
      const generalErr = error as APIErrors;
      setGeneralErrors(
        generalErr.error || 'Something went wrong, please try again'
      );
    }
  };

  const mutateOrganizationMembers = async () => {
    try {
      await mutateOrgSubscriptionDataSWR();
      console.log('Organization Members updated');
    } catch (error) {
      const generalErr = error as APIErrors;
      setGeneralErrors(
        generalErr.error || 'Something went wrong, please try again'
      );
    }
  };

  return (
    <SettingsContext.Provider
      value={{
        numInvitesRemaining,
        inviteLink,
        stripeSubscriptionData,
        featureLimitationsList: featureLimitations,
        teamMembers,
        organizationMembers,
        FLLoading: FeatureLimitLoading,
        userSelectedDifferentFL,
        mutateFLSWR,
        mutateOrganizationMembers,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettingsContext = (): SettingsContextInterface => {
  const context = useContext(SettingsContext);
  if (context === undefined) {
    throw new Error('SettingsContext must be within SettingsContextProvider');
  }

  return context;
};
